import { Location } from '@components/Hotels/types';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useEvents } from '@events/EventsProvider';
import useAmplitudePayloads from '@hooks/useAmplitudePayloads';
import useDoOnce from '@hooks/useDoOnce';
import { SearchState } from '@customTypes/search';
import HotelApi, { HotSpotHotelApi } from '@customTypes/hotel-api';
import {
  VIEW_SEARCH_RESULTS_PAGE,
  SELECT_HOTEL_CARD,
  APPLY_FILTER,
  APPLY_PRODUCT_CATEGORY,
  VIEW_NO_RESULTS_PAGE,
  VIEW_HOTEL_CARD,
  SEARCH,
  SELECT_SEARCH_LOCATION,
  START_SELECT_SEARCH_LOCATION,
  IM_FLEXIBLE_BUTTON_CLICKED,
  CANCEL_SEARCH_DATE,
  CANCEL_SEARCH_FILTER,
} from '@constants/amplitudeEvents';
import {
  VibesFriendlyNames,
  AmenitiesFriendlyNames,
  GuestRatingFriendlyNames,
  HotelStarsFriendlyNames,
} from '@context/SearchContext2025';

const getFilterPayload = (searchState: SearchState) => {
  const filterByAmenities = Object.entries(searchState.filters.amenities)
    .filter(([, value]) => value)
    .map(([key]) => key as keyof SearchState['filters']['amenities'])
    .map((key) => AmenitiesFriendlyNames[key]);

  const filterByGuestRatings = Object.entries(searchState.filters.guest_ratings)
    .filter(([, value]) => value)
    .map(([key]) => key as keyof SearchState['filters']['guest_ratings'])
    .map((key) => GuestRatingFriendlyNames[key]);

  const filterByVibes = Object.entries(searchState.filters.vibes)
    .filter(([, value]) => value)
    .map(([key]) => key as keyof SearchState['filters']['vibes'])
    .map((key) => VibesFriendlyNames[key]);

  const filterByHotelClass = Object.entries(searchState.filters.hotel_stars)
    .filter(([, value]) => value)
    .map(([key]) => key as keyof SearchState['filters']['hotel_stars'])
    .map((key) => HotelStarsFriendlyNames[key]);
  return {
    filter_by_amenities: filterByAmenities,
    filter_by_guest_rating: filterByGuestRatings,
    filter_by_hotel_vibes: filterByVibes,
    filter_by_only_available: searchState.filters.only_available,
    filter_by_hotel_class: filterByHotelClass,
    filter_by_product_category: searchState.product_category || 'all',
  };
};

const getSearchParamsPayload = (searchState: SearchState) => ({
  search_date: searchState.date,
  search_city: searchState.city.toLowerCase(),
  search_state: searchState.state_code.toLowerCase(),
  search_country: searchState.country_code.toLowerCase(),
  search_hotel_name: searchState.hotel_name.toLowerCase(),
  product_category: searchState.product_category
    ? searchState.product_category.toLowerCase()
    : 'all',
});

/**
 * useAmplitudeViewSRPTracker is used to track
 * when a user views the SRP
 * @returns tracking function
 */
export function useAmplitudeViewSRPTracker() {
  const { track } = useEvents();
  return useCallback(
    (searchState: SearchState, resultHotels: HotelApi[], resultHotspots: HotSpotHotelApi[]) => {
      const { city, state_code: stateCode, hotel_name: hotelName } = searchState;
      const srpType = (() => {
        if (hotelName) return 'hotel';
        if (city) return 'city';
        if (stateCode) return 'state';
        return 'country';
      })();

      track(
        VIEW_SEARCH_RESULTS_PAGE,
        {
          ...getSearchParamsPayload(searchState),
          srp_type: srpType,
          hotel_sort_order: resultHotels?.map((h) => h.name),
          hot_spot_hotels:
            resultHotspots?.map(
              (hotSpotHotel) =>
                resultHotels.find((hotel: HotelApi) => hotel.id === hotSpotHotel.hotel_id)?.name,
            ) || [],
        },
        { withURLandTitle: true },
      );
    },
    [track],
  );
}

/**
 * useAmplitudeApplyFilterTracker is used to track
 * when a user applies a filter on the SRP
 * @returns tracking function
 */
export function useAmplitudeApplyFilterTracker(source?: string) {
  const { track } = useEvents();
  const router = useRouter();
  const computedSource = (() => {
    if (source) return source;
    if (router.pathname === '/') return 'homepage';
    return 'srp';
  })();
  return useCallback(
    (searchState: SearchState) => {
      track(
        APPLY_FILTER,
        {
          source: computedSource,
          ...getFilterPayload(searchState),
          ...getSearchParamsPayload(searchState),
        },
        { withURLandTitle: true },
      );
    },
    [track, computedSource],
  );
}

/**
 * useAmplitudeSelectCardTracker is used to track
 * when a user selects a hotel card on the SRP
 * @param source: The source of the event (homepage, srp, dlp etc)
 * @returns tracking function
 */
export function useAmplitudeSelectCardTracker(source?: string) {
  const { track } = useEvents();
  const { generateHotelPayload } = useAmplitudePayloads();
  const router = useRouter();
  const computedSource = (() => {
    if (source) return source;
    if (router.pathname === '/') return 'homepage';
    return 'srp';
  })();
  return useCallback(
    (searchState: SearchState, hotel: HotelApi, index?: number) => {
      track(
        SELECT_HOTEL_CARD,
        {
          source: computedSource,
          ...generateHotelPayload(hotel, undefined),
          ...getFilterPayload(searchState),
          ...getSearchParamsPayload(searchState),
          ...(index !== undefined ? { index } : {}),
        },
        { withURLandTitle: true },
      );
    },
    [track, computedSource, generateHotelPayload],
  );
}

/**
 * useAmplitudeViewHotelCardTracker is used to track
 * when a user views a hotel card on the SRP
 * @returns tracking function
 */
export function useAmplitudeViewHotelCardsTracker(source?: string) {
  const { track } = useEvents();
  const router = useRouter();
  const computedSource = (() => {
    if (source) return source;
    if (router.pathname === '/') return 'homepage';
    return 'srp';
  })();
  return useCallback(
    (searchState: SearchState, hotels: HotelApi[]) => {
      track(
        VIEW_HOTEL_CARD,
        {
          source: computedSource,
          hotels: hotels.map((hotel) => hotel.name),
          ...getFilterPayload(searchState),
          ...getSearchParamsPayload(searchState),
        },
        { withURLandTitle: true },
      );
    },
    [track, computedSource],
  );
}

/**
 * useAmplitudeViewHotelCardTracker is used to track
 * when there are no results on the SRP
 * @returns tracking function
 */
export function useAmplitudeNoResultsTracker(source: string = 'srp') {
  const { track } = useEvents();
  const router = useRouter();
  const computedSource = (() => {
    if (source) return source;
    if (router.pathname === '/') return 'homepage';
    return 'srp';
  })();
  return useCallback(
    (searchState: SearchState) => {
      track(
        VIEW_NO_RESULTS_PAGE,
        {
          source: computedSource,
          ...getFilterPayload(searchState),
          ...getSearchParamsPayload(searchState),
        },
        { withURLandTitle: true },
      );
    },
    [track, computedSource],
  );
}

/**
 * useAmplitudeApplyProductCategoryTracker is used to track
 * when a user selects a product category filter
 * @param source: The source of the event (homepage, srp, dlp etc)
 * @returns tracking function
 */
export function useAmplitudeApplyProductCategoryTracker(source?: string) {
  const { track } = useEvents();
  const router = useRouter();
  const computedSource = (() => {
    if (source) return source;
    if (router.pathname === '/') return 'homepage';
    return 'srp';
  })();
  return useCallback(
    (category: string) => {
      track(
        APPLY_PRODUCT_CATEGORY,
        {
          source: computedSource,
          product_category: category,
        },
        { withURLandTitle: true },
      );
    },
    [track, computedSource],
  );
}

/**
 * useAmplitudeSearchTracker is used to track
 * when a user conducts a search
 * @param source: The source of the event (homepage, srp, dlp etc)
 * @returns tracking function
 */
export function useAmplitudeSearchTracker(source?: string) {
  const { track } = useEvents();
  const router = useRouter();
  const computedSource = (() => {
    if (source) return source;
    if (router.pathname === '/') return 'homepage';
    return 'srp';
  })();
  return useCallback(
    (searchState: SearchState) => {
      track(
        SEARCH,
        {
          source: computedSource,
          ...getFilterPayload(searchState),
          ...getSearchParamsPayload(searchState),
        },
        { withURLandTitle: true },
      );
    },
    [track, computedSource],
  );
}

/**
 * useAmplitudeStartSelectSearchLocationTracker is used to track
 * when a user starts to input in a typeahead search.
 * @param source: The source of the event (homepage, srp, dlp etc)
 * @returns tracking function
 */
export function useAmplitudeStartSelectSearchLocationTracker(source?: string) {
  const { track } = useEvents();
  const router = useRouter();
  const computedSource = (() => {
    if (source) return source;
    if (router.pathname === '/') return 'homepage';
    return 'srp';
  })();
  const trackFn = useCallback(() => {
    track(
      START_SELECT_SEARCH_LOCATION,
      {
        source: computedSource,
      },
      { withURLandTitle: true },
    );
  }, [track, computedSource]);
  return useDoOnce(trackFn);
}

/**
 * useAmplitudeTypeaheadTracker is used to track
 * when a user selects a location from the typeahead
 * @param(source): The source of the event (homepage, srp, dlp etc)
 * @returns tracking function
 */
export function useAmplitudeTypeaheadTracker(source?: string) {
  const { track } = useEvents();
  const { generateSearchLocationPayload } = useAmplitudePayloads();
  const router = useRouter();
  const computedSource = (() => {
    if (source) return source;
    if (router.pathname === '/') return 'homepage';
    return 'srp';
  })();

  const generateNewSearchUrl = (location: Location) => {
    const { country_code: countryCode, state_code: stateCode, city_name: cityName } = location;

    const path = ['s', countryCode, stateCode, cityName, 'hotel-day-passes']
      .filter((loc) => loc)
      .map((name) => name.toLowerCase())
      .join('/');

    const url = new URL(window.location.href);
    url.pathname = `/${path}`;
    return url.toString();
  };

  return useCallback(
    (location: Location) => {
      track(
        SELECT_SEARCH_LOCATION,
        {
          source: computedSource,
          ...generateSearchLocationPayload(location),
          search_location_url: generateNewSearchUrl(location),
        },
        { withURLandTitle: true },
      );
    },
    [track, generateSearchLocationPayload, computedSource],
  );
}

/**
 * useAmplitudeFlexibleDateTracker is used to track when
 * a user clicks on the I'm Flexible option on the datepicker
 * @param source: The source of the event (homepage, srp, dlp etc)
 * @returns tracking function
 */
export function useAmplitudeFlexibleDateTracker(source?: string) {
  const { track } = useEvents();
  const router = useRouter();
  const computedSource = (() => {
    if (source) return source;
    if (router.pathname === '/') return 'homepage';
    return 'srp';
  })();
  return useCallback(
    (searchState: SearchState) => {
      track(
        IM_FLEXIBLE_BUTTON_CLICKED,
        {
          source: computedSource,
          ...getFilterPayload(searchState),
          ...getSearchParamsPayload(searchState),
        },
        { withURLandTitle: true },
      );
    },
    [track, computedSource],
  );
}

/**
 * useAmplitudeDateCancelTracker is used to track when
 * a user clicks on the cancel button on the datepicker
 * @param source: The source of the event (homepage, srp, dlp etc)
 * @returns tracking function
 */
export function useAmplitudeDateCancelTracker(source?: string) {
  const { track } = useEvents();
  const router = useRouter();
  const computedSource = (() => {
    if (source) return source;
    if (router.pathname === '/') return 'homepage';
    return 'srp';
  })();
  return useCallback(
    (searchState: SearchState) => {
      track(
        CANCEL_SEARCH_DATE,
        {
          source: computedSource,
          ...getFilterPayload(searchState),
          ...getSearchParamsPayload(searchState),
        },
        { withURLandTitle: true },
      );
    },
    [track, computedSource],
  );
}

/**
 * useAmplitudeFilterCancelTracker is used to track when
 * a user clicks on the close button on the filter drawer
 * @param source: The source of the event (homepage, srp, dlp etc)
 * @returns tracking function
 */
export function useAmplitudeFilterCancelTracker(source?: string) {
  const { track } = useEvents();
  const router = useRouter();
  const computedSource = (() => {
    if (source) return source;
    if (router.pathname === '/') return 'homepage';
    return 'srp';
  })();
  return useCallback(
    (searchState: SearchState) => {
      track(
        CANCEL_SEARCH_FILTER,
        {
          source: computedSource,
          ...getFilterPayload(searchState),
          ...getSearchParamsPayload(searchState),
        },
        { withURLandTitle: true },
      );
    },
    [track, computedSource],
  );
}
